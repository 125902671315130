<template>
  <section>
    <article class="container">
      <main class="card table-list">
        <h3 class="flex-between-center">
          <p>Tokens</p>
          <p class="basic-group">
            <input
              @keydown.enter="dbSearchAction"
              @input="dbSearchAction"
              v-model="search_info"
              type="text"
              :placeholder="$t('SearchToken')"
              autocomplete="off"
            />
            <button @click.stop="dbSearchAction">
              <img src="@/static/img/icon/search_pink.png" width="16" />
            </button>
          </p>
        </h3>
        <div class="table-responsive">
          <table v-if="blocks && blocks.length > 0">
            <thead>
              <tr>
                <th>#</th>
                <th>Token</th>
                <th>{{ $t("address") }}</th>
                <th>{{ $t("TotalSupply") }}</th>
                <!-- <th>{{ $t("Holders") }}</th> -->
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item,i) in blocks" :key="item.id">
                <td>{{(page-1)*limit+i+1}}</td>
                <td>
                  <router-link :to="'/token/' + item.contract_address">
                    <img v-if="item.icon" :src="item.icon" height="16">
                    <span>{{item.name}}</span>
                    <img v-if="item.verified" src="@/static/img/icon/contract-verify.png" height="16">
                    <img v-if="item.official" src="@/static/img/icon/official-verify.png" height="16">
                  </router-link>
                </td>
                <td>
                  <router-link :to="'/evm/' +item.contract_address">
                  {{item.contract_address}}</router-link>
                </td>
                <td>{{webUtil.formatNumber(item.total_supply, item.decimals)}}</td>
                <!-- <td>{{item.holders?webUtil.addCommas(item.holders,0):'--'}}</td> -->
              </tr>
            </tbody>
          </table>
          <div class="null" v-else>{{ $t("noInformation") }}</div>
          <loading :loading="isLoading"></loading>
        </div>
        <!-- 翻页 -->
        <pagination-view
          :limit="limit"
          :total="total"
          :page="page"
          @emitPage="getTokens"
        ></pagination-view>
      </main>
    </article>
  </section>
</template>

<script>
import PaginationView from "base/pagination";
import loading from "base/loading";
export default {
  data() {
    return {
      blocks: null,
      isLoading: true,
      total: 0,
      limit: 20,
      page:1,
      search_info: "",
    };
  },
  created() {
    this.getTokens();
    this.dbSearchAction = this.lodash.debounce(this.searchAction, 500);
  },
  methods: {
    getTokens(page=1) {
      if(this.search_info.trim()){
        this.searchAction();
      }else{
        this.page = page;
        this.isLoading = true;
        let offset = this.webUtil.BN(page-1).times(this.limit).toFixed();

        this.axios
          .get(this.domain + "evm/tokens?limit="+this.limit+"&offset=" + offset)
          .then(res => {
            this.blocks = res.data.data;
            this.total = res.data.pagination.total;
            this.isLoading = false;
          })
          .catch(() => {
            this.blocks = null;
            this.isLoading = false;
            console.log("Get blocks failed");
          });
      }
    },
    searchAction(){
      let value = this.search_info.trim();
      if (value) {
        this.isLoading = true;
        this.total = 0;
        this.axios.get(this.domain+'evm/tokens/search/'+value).then(res=>{
          this.isLoading = false;
          if(res.data.code==0){
            this.blocks = res.data.data;
          }
        }).catch(()=>{
          this.isLoading = false;
          this.blocks = null;
        })
      }else{
        this.getTokens();
      }
    }
  },
  components: {
    PaginationView,
    loading
  },
};
</script>

<style scoped>
.table-list .basic-group{
  max-width: 256px;
  height: 32px;
}
.table-list input{
  box-shadow: none;
  font: 400 12px/16px Gotham-Book;
  border: var(--border);
  padding: 8px 28px 8px 8px;
}
.table-list img,.table-list span{
  margin-right: 6px;
}
button{
  padding: 0 6px;
}
</style>
