<template>
  <div class="page-pagination" v-if="totalPage>1">
    <template v-if="page==1">
      <img src="@/static/img/icon/first_disabled@2x.png" width="16" />
      <img src="@/static/img/icon/previous_disabled.png" width="16" />
    </template>
    <template v-else>
      <!-- first btn -->
      <img src="@/static/img/icon/first@2x.png" width="16" class="clickable" @click="turnPage(1)" />
      <!-- previous btn -->
      <img
        src="@/static/img/icon/previous.png"
        width="16"
        class="clickable"
        @click="turnPage('previous')"
      />
    </template>
    <span class="pageOf"><b>{{page}}</b> <i>/ {{totalPage}}</i></span>
    <template v-if="page==totalPage">
      <img src="@/static/img/icon/next_disabled@2x.png" width="16" />
      <img src="@/static/img/icon/last_disabled@2x.png" width="16" />
    </template>
    <template v-else>
      <!-- next btn -->
      <img src="@/static/img/icon/next@2x.png" width="16" class="clickable" @click="turnPage('next')" />
      <!-- last btn -->
      <img
        src="@/static/img/icon/last@2x.png"
        width="16"
        class="clickable"
        @click="turnPage(totalPage)"
      />
    </template>
  </div>
</template>
<script>
export default {
  props: ["total","limit","type","from","page"],
  // data() {
  //   return {
  //     currentPage:1,//当前第几页
  //   }
  // },
  computed: {
    totalPage(){//共多少页数据
      if(this.total&&this.limit){
        return Math.ceil(this.total/this.limit);
      }
      return 0
    }
  },
  methods: {
    turnPage(num=1){//翻页
      let currentPage = this.page;
      switch (num){
        case 'previous':
          currentPage--;
          break;
        case 'next':
          currentPage++;
          break;
        default:
          currentPage = num;
      }
      if(this.from=='evm'){
        this.$emit("emitPage", currentPage-1); //接口数据page从0开始
      }else{
        this.$emit("emitPage", currentPage); //接口数据page从1开始
      }
    },
  },
}
</script>
<style>
.page-pagination {
  text-align: right;
  margin-top: 15px;
  padding-top: 20px;
  border-top: var(--border);
  font-size: 12px;
}
.page-pagination img,
.page-pagination span {
  margin-left: 16px;
}
.page-pagination .clickable {
  cursor: pointer;
}
.page-pagination i {
  color: var(--greyColor);
}
</style>
